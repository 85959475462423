<template>
  <div>
    <v-toolbar
      class="pt-2"
      extension-height="24px"
      height="24px"
      flat
      dense
      extended
    >
      <v-toolbar-title class="grey--text text--darken-2 text-subtitle-2">
        {{ dash.title }}
      </v-toolbar-title>

      <v-spacer />

      <template v-if="$vuetify.breakpoint.smAndUp">
        <v-btn
          small
          icon
          @click="edit()"
        >
          <v-icon
            small
            dense
          >
            mdi-settings
          </v-icon>
        </v-btn>
        <v-btn
          small
          icon
          @click="refresh()"
        >
          <v-icon
            small
            dense
          >
            mdi-refresh
          </v-icon>
        </v-btn>
      </template>
      <template #extension>
        <div class="text-caption grey--text text--darken-2">
          {{ dash.description }}
        </div>
      </template>
    </v-toolbar>
    <v-skeleton-loader
      ref="skeleton"
      type="list-item-avatar-three-line"
      class="mx-auto"
    />
  </div>
</template>

<script>
export default {
    name: 'DashLoading',
    props: {
      dash: {
        type: Object,
        default: () => {}
      }
    },
    methods: {
        edit() {
            this.$emit('edit')
        },
        refresh() {
            this.$emit('refresh')
        }
    }
}
</script>